import PropTypes from 'prop-types'
import React from 'react'
import { Dropdown, NavDropdown } from 'react-bootstrap'
import ImmutablePropTypes from 'react-immutable-proptypes'

import styles from './styles.module.scss'
import OverlayTriggerIfEllipsedText from './OverlayTriggerIfEllipsedText'

const itemShape = {
  code: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
}

const getItem = (item, valueItem) => {
  return (
    <div
      className={valueItem ? styles.menuValueItem : styles.menuItem}
      role="menuitem"
    >
      <OverlayTriggerIfEllipsedText
        className={styles.companyName}
        disabled={valueItem}
      >
        {item.name}
      </OverlayTriggerIfEllipsedText>
      {!valueItem && <small className={styles.menuCode}> {item.code}</small>}
    </div>
  )
}

const CustomerCompanyDropdownItem = ({ item, navigate }) => (
  <Dropdown.Item
    onClick={() => {
      navigate(item.linkTo)
    }}
  >
    {getItem(item)}
  </Dropdown.Item>
)

CustomerCompanyDropdownItem.propTypes = {
  item: PropTypes.shape(itemShape).isRequired,
  navigate: PropTypes.func,
}

class CustomerCompanyDropdown extends React.Component {
  state = {
    open: false,
  }

  // Thanks to the arrogant devs (taion) @ https://github.com/react-bootstrap/react-bootstrap/issues/1490
  // We have to take stateful actions for closing the dropdown when item with link is clicked.
  // SORRY marttik 26.9.2016

  onToggle = (newValue) => {
    if (this.forceClose) {
      this.setState({ open: false })
      this.forceClose = false
    } else {
      this.setState({ open: newValue })
    }
  }

  // See above. this.props.navigate comes from connected container NavigationBar.
  // Simple use of <Link /> from react-router-bootstrap does not cut it because of the story above.
  // SORRY marttik 26.9.2016

  onMenuItemClick = (url) => {
    this.setState({ open: false })
    this.props.navigate(url)
  }

  render() {
    const { id, items, selectedItemCode, defaultTitle, header } = this.props
    const selectedItem =
      items &&
      selectedItemCode &&
      items.find((i) => i.code === selectedItemCode)

    const disabled = !items || items.count === 0
    const title = selectedItem ? getItem(selectedItem, true) : defaultTitle

    return (
      <NavDropdown
        id={id}
        open={this.state.open}
        onToggle={this.onToggle}
        title={
          <>
            <span className={styles.header}>{header}</span>
            {title}
          </>
        }
        disabled={disabled}
        className={`${
          this.state.open ? styles.activeLogin : styles.deactiveLogin
        } ${!selectedItem ? styles.noSelection : styles.navSelection}`}
      >
        {items &&
          items.map((item, index) => (
            <CustomerCompanyDropdownItem
              item={item}
              key={index}
              eventKey={index}
              navigate={this.onMenuItemClick}
            />
          ))}
      </NavDropdown>
    )
  }
}

CustomerCompanyDropdown.propTypes = {
  id: PropTypes.string,
  items: ImmutablePropTypes.list,
  selectedItemCode: PropTypes.string,
  defaultTitle: PropTypes.string.isRequired,
  navigate: PropTypes.func,
}

export default CustomerCompanyDropdown
