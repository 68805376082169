import PropTypes from 'prop-types'
import React from 'react'

import { apiConfig } from 'api/api-utils'

import './styles.scss'

const api = apiConfig
const backEndsAreNotLocal = () => {
  const be = new URL(api.backendUrl)
  const gt = new URL(api.gtUrl)
  return be.hostname !== 'localhost' || gt.hostname !== 'localhost'
}
function getRandomColor() {
  var letters = '0123456789ABCDEF'
  var color = '#'
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}
function getInfoBarColor() {
  if (backEndsAreNotLocal()) return getRandomColor()
  return 'fff'
}
const propTypes = {
  children: PropTypes.node,
}

const DevelopmentInfo = (props) =>
  process.env.NODE_ENV === 'development' && backEndsAreNotLocal() ? (
    <div
      className={'developmentInfo'}
      style={{ backgroundColor: getInfoBarColor() }}
    >
      <span>API:</span> {api.backendUrl}
      <span>GT:</span> {api.gtUrl}
      {props.children}
    </div>
  ) : null

DevelopmentInfo.propTypes = propTypes

export default DevelopmentInfo
