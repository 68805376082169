import { call, put, takeEvery } from 'redux-saga/effects'
import * as Diff from 'immutablediff'
import { Record } from 'immutable'

import { handleError } from 'api/api-utils'
import customerApi from 'api/customer-management-api'

import { UPDATE_CUSTOMER } from './constants'

import { updateCustomerSuccess, updateCustomerError } from './actions'

// When updating a Customer, we dont want to touch any other property than:
const UpdateCustomerRecord = Record({
  accountingLockDate: undefined,
  address: undefined,
  customLogoUrl: undefined,
  customNavbarBackgroundColor: undefined,
  customNavbarColor: undefined,
  hideSystemDashboards: undefined,
  hideSystemReportSchemes: undefined,
  customNavbarTextColor: undefined,
  forceAAD: undefined,
  name: undefined,
})

export function* updateCustomer(action) {
  const { customerCode, customer, updatedCustomer } = action
  const patch = Diff(
    new UpdateCustomerRecord(customer),
    new UpdateCustomerRecord(updatedCustomer)
  ).toJS()
  try {
    const updatedApiCustomer = yield call(
      customerApi.patchCustomer,
      customerCode,
      patch
    )
    yield put(updateCustomerSuccess({ customer: updatedApiCustomer }))
  } catch (error) {
    yield put(handleError(error, updateCustomerError))
  }
}

export function* customerSaga() {
  yield takeEvery(UPDATE_CUSTOMER, updateCustomer)
}

// All sagas to be loaded
export default customerSaga
