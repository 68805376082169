import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { NavDropdown } from 'react-bootstrap'
import styles from './styles.module.scss'

import { UserInfoNaviItem } from './UserInfoNaviItem.js'
import useAD from 'utils/featureFlagReaders/useAD'

const UserDropdown = (props) => {
  const { onLogout, username, header, onSettings, subUsers } = props
  const [open, setOpen] = useState(false)

  return (
    <NavDropdown
      title={
        <span className={styles.navOption}>
          <span className={styles.header}>{header}</span>
          {username}
        </span>
      }
      open={open}
      onToggle={(state) => setOpen(state)}
      id="nav-user-dropdown"
      data-testing-id="nav-user-dropdown"
      data-hj-suppress
      className={open ? styles.activeLogin : styles.deactiveLogin}
    >
      <UserInfoNaviItem
        settingsAction={onSettings}
        subUsers={subUsers}
        logoutAction={onLogout}
      />
    </NavDropdown>
  )
}

UserDropdown.propTypes = {
  logoutText: PropTypes.string.isRequired,
  onLogout: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  subUsers: useAD ? PropTypes.object.isRequired : PropTypes.object,
}

export default UserDropdown
