import React from 'react'
import Form from 'react-formal'
import { FormattedMessage, injectIntl } from 'react-intl'
import * as yup from 'yup'

import { SuccessButton } from 'components/Button'
import FormField from 'components/FormField'

import messages from './messages'
import styles from './styles.module.scss'

import isDev from 'utils/isDevelopment'

import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { passwordLogin } from 'containers/App/actions'

const defaultUser = {
  username: isDev ? process.env.REACT_APP_USERNAME : '',
  password: isDev ? process.env.REACT_APP_PASSWORD : '',
}

const passwordLoginSchema = ({ formatMessage }) =>
  yup.object({
    username: yup
      .string()
      .default(defaultUser.username)
      .email(formatMessage(messages.validationErrorEmail))
      .required(formatMessage(messages.validationErrorUsername)),
    password: yup
      .string()
      .default(defaultUser.password)
      .required(formatMessage(messages.validationErrorPassword)),
  })

const EmailLogin = ({ intl: { formatMessage } }) => {
  const schema = passwordLoginSchema({ formatMessage })
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const handleSubmit = (form) => {
    dispatch(passwordLogin({ ...form, navigate }))
  }
  return (
    <Form
      schema={schema}
      defaultValue={schema.default()}
      onSubmit={handleSubmit}
    >
      <div className={styles.emailLogin}>
        <FormField name="username" placeholder="Username" />
        <FormField name="password" placeholder="Password" type="password" />
        <SuccessButton block type="submit">
          <FormattedMessage {...messages.emailLoginSubmitBtnTxt} />
        </SuccessButton>
      </div>
    </Form>
  )
}

export default injectIntl(EmailLogin)
