import React from 'react'
import { Alert } from 'react-bootstrap'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Helmet } from 'react-helmet-async'

import Button from 'components/Button'

import messages from './messages'
import styles from './styles.module.scss'

import Logo from './FinazillaLogo.svg'
import useAD from 'utils/featureFlagReaders/useAD'

import AADLogin from './AADLogin'
import { LinkButton } from 'components/Button'
import ErrorComponent from 'components/ErrorComponent'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectGetSubUsersError,
  selectPasswordLoginError,
  selectLoginFlowState,
  selectUserDataFetchError,
  selectUserLoading,
  selectUserProfiles,
  selectLemonsoftOrganizations,
  selectInitializeLemonsoftUserError,
  selectInitializeLemonsoftUserProgress,
} from 'containers/App/selectors'
import {
  ACCOUNT_CONVERSION_STATE,
  CHOOSE_PROFILE_STATE,
  SELECT_LEMONSOFT_ORGANIZATION,
  SELECT_LOGIN_METHOD_STATE,
} from 'containers/App/constants'
import EmailLogin from './EmailLogin'
import AccountConversion from './AccountConversion'
import {
  choseProfile,
  initializeLemonsoftUser,
  logout,
} from 'containers/App/actions'
import tokenStorage from 'api/TokenStorage'
import { useNavigate } from 'react-router'
import LoadingIndicator from 'components/LoadingIndicator'
import { statusCodeToError } from 'api/api-utils'
import LemonLogin from './LemonLogin'
import useLemon from 'utils/featureFlagReaders/useLemon'

const tryParseInvalidFinvoicerIdentityFromQuery = () => {
  try {
    const urlParams = new URLSearchParams(window.location.search)
    var token = urlParams.get('finvoicer_id')
    if (token) {
      var parts = tokenStorage.decodeToken(token)
      return parts?.email
    }
  } catch (e) {
    return ''
  }
  return ''
}

const LoginLoading = (props) => {
  return (
    <>
      <LoadingIndicator {...props} size="large" />
      <SignOutButton />
    </>
  )
}

const SignOutButton = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const handleLogout = () => {
    dispatch(logout(navigate))
  }
  return (
    <Button onClick={handleLogout} className={styles.centeredButton}>
      <FormattedMessage {...messages.signOut} />
    </Button>
  )
}

const LoginError = ({ error }) => {
  if (error?.data?.code === 'E-00031') {
    return (
      <Alert variant="danger">
        <FormattedMessage {...messages.subUserNotFoundError} />
      </Alert>
    )
  }
  return (
    <Alert variant="danger">
      <FormattedMessage {...messages.error} />
    </Alert>
  )
}

const knownErrorMessages = {
  'E-00031': <FormattedMessage {...messages.subUserNotFoundError} />,
  'E-0303': <FormattedMessage {...messages.subUserNotFoundError} />,
}
const knownErrorTitles = {
  'E-0401': <FormattedMessage {...messages.lemonErrorTitle} />,
}
const KnownOrUnkownError = ({ error }) => {
  const activeTokenIdentity =
    tokenStorage.getIdentity() || tryParseInvalidFinvoicerIdentityFromQuery()
  const customMessage =
    knownErrorMessages?.[error?.data?.code] ||
    error?.message ||
    error?.data?.message ||
    error?.data?.Message ||
    error?.jsError ||
    (typeof error?.data === 'string' && error?.data) ||
    `Unknown error ${statusCodeToError(error?.status)}`
  const trimmedMessage =
    customMessage?.split?.(/\r?\n/)?.filter(Boolean)?.[0] || customMessage
  const customTitle = knownErrorTitles?.[error?.data?.code]
  return (
    <>
      <h3>
        {customTitle || (
          <>
            <FormattedMessage {...messages.unableToFetchUser} />{' '}
            {activeTokenIdentity}
          </>
        )}
      </h3>
      <Alert variant="danger">{trimmedMessage}</Alert>
      <SignOutButton />
    </>
  )
}

const Login = () => {
  const loginState = useSelector(selectLoginFlowState)
  const passwordLoginError = useSelector(selectPasswordLoginError)
  const loading = useSelector(selectUserLoading)
  const userDataFetchError = useSelector(selectUserDataFetchError)
  const subUsersError = useSelector(selectGetSubUsersError)
  const profiles = useSelector(selectUserProfiles)
  const organizations = useSelector(selectLemonsoftOrganizations)
  const lemonsoftInitializationProgress = useSelector(
    selectInitializeLemonsoftUserProgress
  )
  const selectOrganizationError = useSelector(
    selectInitializeLemonsoftUserError
  )
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleChooseProfile = (profile) => {
    dispatch(choseProfile(profile, navigate))
  }

  const renderLoginState = () => {
    if (loading) {
      return <LoginLoading progress={lemonsoftInitializationProgress} />
    }
    if (userDataFetchError) {
      return <KnownOrUnkownError error={userDataFetchError} />
    }
    if (selectOrganizationError) {
      return <KnownOrUnkownError error={selectOrganizationError} />
    }
    if (loginState === SELECT_LOGIN_METHOD_STATE) {
      return (
        <>
          {useAD && (
            <>
              <AADLogin />
            </>
          )}
          {useLemon && <LemonLogin />}
          {(useAD || useLemon) && <div className={styles.separator}>OR</div>}
          {passwordLoginError && <LoginError error={passwordLoginError} />}
          <EmailLogin />
        </>
      )
    }
    if (loginState === ACCOUNT_CONVERSION_STATE) {
      return <AccountConversion />
    }
    if (loginState === CHOOSE_PROFILE_STATE) {
      return (
        <>
          <h3>
            <FormattedMessage {...messages.chooseProfileTitle} />
          </h3>
          <ul>
            {subUsersError && <ErrorComponent {...subUsersError} />}
            {profiles
              ?.toJS()
              ?.filter((profile) => profile.isActive)
              ?.map((profile) => (
                <LinkButton
                  key={profile.id}
                  onClick={() => handleChooseProfile(profile)}
                >
                  {profile.name}
                </LinkButton>
              ))}
          </ul>
          <SignOutButton />
        </>
      )
    }
    if (loginState === SELECT_LEMONSOFT_ORGANIZATION) {
      return (
        <>
          <h3>
            <FormattedMessage {...messages.selectOrganization} />
          </h3>
          <ul>
            {organizations?.toJS()?.map((organization) => (
              <LinkButton
                key={organization.id}
                onClick={() =>
                  dispatch(
                    initializeLemonsoftUser({
                      organizationId: organization.id,
                      navigate,
                    })
                  )
                }
              >
                {organization?.name}
              </LinkButton>
            ))}
          </ul>
          <SignOutButton />
        </>
      )
    }
    return <LoginLoading progress={lemonsoftInitializationProgress} />
  }

  return (
    <div className={styles.login}>
      <Helmet
        title="Login"
        meta={[{ name: 'description', content: 'Description of Login' }]}
      />
      <div className={styles.loginPanel}>
        <header className={styles.panelHeading} id="logo">
          <img src={Logo} alt="Finazilla" />
        </header>
        <div className={styles.panel}>{renderLoginState()}</div>
      </div>
    </div>
  )
}

export default injectIntl(Login)
